<template>
    <div class="dashboard-container">
      <div class="app-container" style="margin-top:20px;margin-right:20px;margin-bottom: 20px;">
        <el-row>
          <el-col :span="24" align="right">
            <el-button class="el-icon-edit"    type="primary" size="small" @click="add"
            
              >添加轮播图</el-button
            >
          </el-col>
        </el-row>
      </div>
  
  
      <el-dialog
        title="添加轮播图"
        :visible.sync="showDialog"
        width="60%"
        @close="btnCancel"
      >
      <el-form :rules="rules" ref="perForm" label-width="200px">
      <el-form-item label="上传类型" v-model="fileType" prop="fileDtoList" >
          <el-radio-group v-model="fileType">
            <el-radio label="1">轮播图片</el-radio>
            <el-radio label="2">合同图片</el-radio>
          </el-radio-group>
        </el-form-item>
      
         <el-form-item label="关联合同" v-if="fileType==2">
          <el-select
          placeholder="选择合同"
          class="ipt"
          @change="selectChange"
          v-model="bmsHomeRotationDto.contractId"
       
        
        >
          <el-option
            v-for="item in res"
            :key="item.contractId"
            :label="item.title"
            :value="item.contractId"
          ></el-option>
        </el-select>
         </el-form-item>
            <el-form-item label="轮播图片" prop="fileList" v-if="fileType==1"> 
          <el-upload
            list-type="picture-card"
            action="http://up.qiniup.com"
            :file-list="fileList"
            :limit="8"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess2"
            :data="postData"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <!-- <div style="color: green">上传轮播图的比例为 5:4</div> -->
        </el-form-item>
        <el-form-item
          label="合同图片"
          prop="originId"
          v-if="fileType==2"
        >
          <el-table
            :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
            :data="tabelList"
            border
            :key="bmsHomeRotationDto.contractId"
            style="width: 500px"
            ref="singleTable"
            class="emp_table"
            @selection-change="handleSelectionChange"
            v-if="tabelList"
          >
            <el-table-column type="selection" align="center" width="55" />
        

            <el-table-column
            
              align="center"
         
              label="图片"
              width="200px"
            >
              <template slot-scope="scope">
                <div class="permitImg-img">
                  <el-image
                    style="width: 35%"
                    :src="'http://farmfile.uutele.com/' + scope.row"
                    :preview-src-list="[
                      'http://farmfile.uutele.com/' + scope.row
                    ]"
                  />
                </div>
              </template>
            </el-table-column>

           

            
          </el-table>
        
         
        </el-form-item>
      </el-form>
       
      
        <span slot="footer" class="dialog-footer">
          <el-button @click="btnCancel">取 消</el-button>
          <el-button type="primary" @click="enter">确 定</el-button>
        </span>
      </el-dialog>
      <div class="tab" style="margin-left: 30px;">
        <el-table
          :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
          :data="activeData"
          border
          style="width:100%"
          ref="activeData"
          class="emp_table"
          v-if="activeData"
        >
       
          <el-table-column
            align="center"
            prop="fids"
            label="图片"
        width="200"
          >
            <template slot-scope="scope">
              <div class="permitImg-img">
                <el-image
                  style="width:50%"
                  :src="' http://farmfile.uutele.com/' + scope.row.fid"
                  :preview-src-list="[' http://farmfile.uutele.com/' + scope.row.fid]"
                />
              </div>
            </template>
          </el-table-column>
       

  
          <el-table-column align="center" label="操作"   >
            <template slot-scope="scope">
            
              <el-button
                type="text"
                size="small"
                @click="delCommonMsg(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
     
      </div>
    </div>
  </template>
  
  <script>
  
  import {
    gehtfid,
    addswiper,
    getcontractInfo,
    getswiper,
    delswiper,
    getUploadToken,
  
  } from "../../request/http";
  export default {
  
    data() {
      return {
        fileType:null,
      dialogVisible: false,
        addorredit: false,
        // 表格配置
        qc:{
          companyId:null,
          pageNum:1,
          pageSize:1000
        }
       ,
        // 表格数据
        res:[],
        tabelList:[],
        activeData: [],
        showDialog: false,
        fileList: [], //多张照片回显
        bmsHomeRotationDto: {
            companyId:'',
            contractId:'',
            fileList:[],
      
        },
        albumFileIds:[],
        dialogImageUrl: "",
        postData: {
          token: "",
          key: "",
        },
        rules: { fileList: [
                    { required: true, message: "轮播图片不能为空", trigger: "blur" },
                ],
         
        },
      };
    },
    created() {
      this.show();
     this.bmsHomeRotationDto.companyId=Number(localStorage.getItem('companyId'))
     this.qc.companyId=Number(localStorage.getItem('companyId'))
     this.getcontractInfo();
      console.log(this.activeData.length);
    },
    methods: {

     async selectChange(value) {
      this.bmsHomeRotationDto.contractId = value
      console.log( this.bmsHomeRotationDto.contractId)
       let {data} = await gehtfid(this.bmsHomeRotationDto.contractId)
      this.tabelList=data.data
      console.log(this.tabelList);
 
     
    },
    handleSelectionChange(val) {

      this.albumFileIds=val,
      console.log(this.albumFileIds);
     
    },




     async getcontractInfo(){
        let { data } = await getcontractInfo(this.qc);
     
        this.res =data.data.records
        console.log(   this.res);
      },
     

   

          //删除多张图片的一张
    handleRemove(file, fileList) {
      let key;

file.key ? key = file.key : key = file.response.key

      let i =this.bmsHomeRotationDto.fileList.findIndex((item) => item.fid === key)
   
      // console.log(i);
     this.bmsHomeRotationDto.fileList.splice(i, 1);
     this.bmsHomeRotationDto.fileList.splice(i, 1);
     console.log();
    },
    //点击放大，查看图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
        async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      console.log(data.data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.data.token;
      this.postData.key = data.data.fid;
    },
              // 上传多张图片方法
    handleAvatarSuccess2(res, file) {
      // console.log("上传成功", res, file);
      if(this.fileType==1){
        this.bmsHomeRotationDto.fileList.push({
          fileType:1,
          fid:res.key
        });
      }
  else if(this.fileType==2){
        this.bmsHomeRotationDto.fileList.push({
          fileType:2,
          fid:res.key
        });
      }

      this.$message.success("上传图片成功");
  //this.bmsHomeRotationDto.fids += res.key+','
   console.log(this.bmsHomeRotationDto.fileList);
  
   
    },
      //取消
      btnCancel() {
       this.bmsHomeRotationDto =  {
            companyId:'',
            contractId:null,
            fileList:[],
      
        },
        this.fileType=null
        this.tabelList=[]
        this.fileList=[]
        this.$refs.perForm.resetFields();
        this.showDialog = false;
      },
      //删除
       delCommonMsg(row){
        this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await delswiper(row.id)
          console.log(res);
          res.data.code == 0 &&
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
       
         
            this.show();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
          
                   
      },
      
      //打开窗口
      add() {
        this.showDialog = true;
      
       this.bmsHomeRotationDto.companyId=Number(localStorage.getItem('companyId'))

      },
      //列表
      show() {
        getswiper().then((res) => {
          console.log(res.data);
          if(res.data){
  this.activeData = res.data.data;
          }
          else {
            this.activeData=[]
          }
          
        });
        console.log( this.activeData);
      },
     
      //添加
      enter() {
       

    if(this.albumFileIds!==[]){
      Object.keys( this.albumFileIds).forEach(key => {
  let item = {
    fid: this.albumFileIds[key],
    fileType:2

  }
  this.bmsHomeRotationDto.fileList.push(item)
})

    }

   console.log(this.bmsHomeRotationDto.fileList);

addswiper(this.bmsHomeRotationDto).then((res) => {
            if (res.data.code == 0)
              this.$message({
                message: "添加成功",
                type: "success",
              });
            this.showDialog = false;
            this.show();
          });
            
        
       
     
        
    
      },
  
    
    
    },
  };
  </script>
  
  <style >
  .el-input {
    /* width: 200px; */
  }
  
  .avatar > img {
    width: 200px;
    height: 200px;
  }
  </style>